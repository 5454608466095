import {isFunction as _isFunc} from 'lodash'

export function isObject(obj: any): obj is object {
    return obj != null && typeof obj === 'object'
}

type AnyFn = (...args: any[]) => any;
type PlainObject = Record<PropertyKey, unknown>;

type ReduceObject<T> = T extends object
    ? T extends AnyFn
        ? PlainObject
        : T extends any[] ? PlainObject : T
    : PlainObject;

// @ts-expect-error TS2677: A type predicate's type must be assignable to its parameter's type.
export function isPlainObject<T>(obj: T): obj is ReduceObject<T> {
    if (!isObject(obj)) return false;
    const proto = Object.getPrototypeOf(obj);
    return proto === null || proto === Object.prototype;
}

export const isFunction: ((obj: any) => obj is AnyFn) = _isFunc

/**
 * Checks if the object is a *valid* date.
 */
export function isDate(x: any): x is Date {
    return Object.prototype.toString.call(x) === "[object Date]" && !isNaN(x)
}
