import React from 'react'
import cn from 'classnames'
import {useTestId} from '../contexts/testid'

// type OnClick = React.JSX.IntrinsicElements['a']['onClick']
//
// type ActionLinkProps = Override<React.ComponentPropsWithoutRef<'a'>, XOR<{ disabled: true } | {
//     disabled?: false
//     onClick: OnClick
// }> & { testId?: string }, 'href'>

type ActionLinkProps = Override<RequiredKeys<React.ComponentPropsWithoutRef<'a'>, 'onClick'>, {
    disabled?: boolean
    testId?: string
}, 'href'>


const ActionLink: React.FC<ActionLinkProps> = ({onClick, className, disabled, testId, ...otherProps}) => {
    const props: React.ComponentPropsWithoutRef<'a'> = otherProps
    const classes = [className];
    (props as any)['data-testid'] = useTestId(testId)
    if(disabled) {
        classes.push('disabled')
        props['aria-disabled'] = true
        props.onClick = (ev) => {
            ev.preventDefault()
        }
    } else if(onClick) {
        props.onClick = (ev) => {
            ev.preventDefault()
            onClick(ev)
        }
    }
    props.className = cn(classes)
    return <a {...props} />
}
ActionLink.displayName = "ActionLink"


export default ActionLink
