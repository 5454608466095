import React, {ReactNode, useState} from 'react'
import ActionButton from './ActionButton'
import ButtonRow from './ButtonRow'
import ModalDialog from './ModalDialog'


interface ConfirmDialogProps {
    children: ReactNode
    close: VoidFn
    onCancel?: VoidFn
    onOk: (() => void) | (() => Promise<void>)
    title?: ReactNode
}

const BUTTON_STYLE = {width:'70px'}

export default function ConfirmDialog(props: ConfirmDialogProps) {
    const [acting, setActing] = useState(false)

    return (
        <ModalDialog minWidth={250} title={props.title || "Confirm"}>
            <div>
                {props.children}
            </div>

            <ButtonRow>
                <ButtonRow.Left/>
                <ButtonRow.Right>
                    <ActionButton tabIndex={1} onClick={() => {
                        setActing(true)
                        Promise.resolve(props.onOk()).then(() => {
                            props.close()
                        }, () => {
                            setActing(false)
                        })
                    }} className="btn-save" disabled={acting} style={BUTTON_STYLE}>OK</ActionButton>
                    <ActionButton tabIndex={0} autoFocus disabled={acting} onClick={() => {
                        setActing(true)
                        if(props.onCancel) {
                            Promise.resolve(props.onCancel()).then(() => {
                                props.close()
                            }, () => {
                                setActing(false)
                            })
                        } else {
                            props.close()
                        }
                    }} style={BUTTON_STYLE}>Cancel</ActionButton>
                </ButtonRow.Right>
            </ButtonRow>
        </ModalDialog>
    )
}
