import React, {forwardRef, ComponentPropsWithoutRef} from 'react'
import Button from './Button'

type ButtonProps = ComponentPropsWithoutRef<typeof Button>

const ActionButton = forwardRef<HTMLButtonElement, ButtonProps>(({onClick, ...otherProps}, ref) => {
    const props: ButtonProps = {
        type: 'button',
        ...otherProps,
    }

    if(onClick != undefined) {
        props.onClick = ev => {
            ev.preventDefault()
            onClick(ev)
        }
    }

    return <Button ref={ref} {...props} />
})

if(__DEV__) {
    ActionButton.displayName = 'ActionButton'
}

export default ActionButton
