import $ from './libs/jquery'
import _ from 'lodash'
import {captureSentryError, logWarningMessage} from './sentry'
import {jsonStringify} from './helpers/jsonSerializable'
// @ts-ignore Circular definition works fine.
import * as _self from './kb'
import {parseFloat} from './util/math'

export {default as notify} from './notify'

// @ts-ignore Circular definition works fine.
export default _self

let _id_counter = 0
export function id() {
    return '_' + (_id_counter++).toString(36) + '_' + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36)
}

export function identity<T>(x: T): T {
    return x
}

export function toBool<T = undefined>(str: any, defaultValue?: T): boolean | T {
    if(_.isString(str)) {
        str = _.trim(str).toLowerCase()
        if(_.includes(['1', 't', 'true', 'y', 'yes', 'on'], str)) return true
        if(_.includes(['0', 'f', 'false', 'n', 'no', 'off'], str)) return false
    } else if(_.isBoolean(str)) return str
    else if(_.isNumber(str)) return str !== 0
    else if(_.isArray(str)) return str.length > 0
    return defaultValue as T
}

export function columnName(number: number) {
    const ordA = 'A'.charCodeAt(0)
    const ordZ = 'Z'.charCodeAt(0)
    const len = ordZ - ordA + 1

    let result = ''
    while(number >= 0) {
        result = String.fromCharCode(number % len + ordA) + result
        number = Math.floor(number / len) - 1
    }
    return result
}

/**
 * Gets the number of characters in a string.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length
 */
export function strlen(str: string | null | undefined): number {
    if(!str) return 0
    return Array.from(String(str)).length
}


export function wrapIdx(idx: number, arrLen: number) {
    if(idx < 0) return arrLen - (-idx) % arrLen
    return idx % arrLen
}

export function numberFormat(number: number|string) {
    const value = parseFloat(number, 0)
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 15,
    })
    return formatter.format(value)
}

const FALLBACK_CURRENCY = 'USD'

/**
 * @deprecated Use formatCurrencyIntl instead.
 */
export function formatCurrency(number: number | string, parens = true): string {
    return formatCurrencyIntl(number, FALLBACK_CURRENCY, parens)
}

export function makeCurrencyFormatter(currencyCode: string) {
    return (n: string | number) => formatCurrencyIntl(n, currencyCode)
}

export function formatCurrencyIntl(number: string | number, currencyCode?: string, parens = true): string {
    const value = parseFloat(number, 0)
    const absValue = Math.abs(value)
    currencyCode ||= window.company?.currencyCode || FALLBACK_CURRENCY
    let str: string
    try {
        str = new Intl.NumberFormat('en-US'/* TODO: support other languages */, {
            style: 'currency',
            currency: currencyCode,
            currencyDisplay: 'narrowSymbol',
        }).format(absValue)
    } catch {
        try {
            str = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
                currencyDisplay: 'symbol',
            }).format(absValue)
        } catch {
            str = `$${absValue}`
        }
    }
    if(parens && value < 0) {
        str = `(${str})`
    }
    return str
}

export function formatHours(hoursStr: string | number) {
    return formatMinutes(Math.round(parseFloat(hoursStr, 0) * 60))
}

export function formatMinutes(mins: number) {
    if(mins < 60) {
        return `${mins} minute${plural(mins)}`
    }
    const hours = Math.floor(mins / 60)
    mins -= hours * 60
    if(mins === 0) {
        return `${hours} hour${plural(hours)}`
    } else {
        return `${hours} hr${plural(hours)}, ${mins} min${plural(mins)}`
    }
}

function plural(num: number, singleSuffix = '', pluralSuffix = 's'): string {
    return num === 1 ? singleSuffix : pluralSuffix
}

const _percentFormatter = new Intl.NumberFormat([], {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
})

export function formatFeePercent(number: number | string, div100 = false): string {
    let pc = parseFloat(number, 0)
    if(div100) pc /= 100
    return _percentFormatter.format(pc)
}

/**
 * Formats a number as a percent.
 *
 * @param number Number between 0 and 1 (inclusive)
 */
export function formatPercentShort(number: number): string {
    if(!number) return '0%'
    if(number < 1) {
        return (number*100).toFixed(1)+'%'
    }
    return '100%'
}

// const SIZE_UNITS = [['B',0], ['kB',1], ['MB',2], ['GB',2], ['TB',3], ['PB',3], ['EB',4], ['ZB',4], ['YB',5]] as const
const SIZE_UNITS = [['B',0], ['KiB',1], ['MiB',2], ['GiB',2], ['TiB',3], ['PiB',3], ['EiB',4], ['ZiB',4], ['YiB',5]] as const
const SIZE_UNITS_END = SIZE_UNITS.length - 1
const BYTES_PER_KB = 1024

export function humanFileSize(bytes: number): string {
    let u, rounded

    for(u=0;;++u) {
        rounded = bytes.toFixed(SIZE_UNITS[u]![1])
        if(u === SIZE_UNITS_END || +rounded < BYTES_PER_KB) {
            break
        }
        bytes /= BYTES_PER_KB
    }

    return `${rounded} ${SIZE_UNITS[u]![0]}`
}


/**
 * Simulates clicking a link. i.e. redirects and puts originating page in browser session history.
 */
export function navigate(url: string) {
    window.location.href = url
}

/**
 * Redirects to another page. Does not add originating page to browser session history.
 */
export function redirect(url: string) {
    window.location.replace(url)
}

/**
 * Refreshes the current page. Will not re-POST data, unlike window.reload. Discards the #hash portion of the URL.
 */
export function refresh() {
    window.location.href = window.location.pathname + window.location.search
}

/**
 * Get the current URL with hostname but without hash.
 */
export function getCurrentUrl() {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`
}

/**
 * Converts whatever to a string. Nullish values become an empty string.
 *
 * @param {Object} x
 * @returns {string}
 */
export function toString(x: any) {
    return x != null ? String(x) : ''
}

function getAttributes(node: Element) {
    return Array.from(node.attributes).reduce<Record<string, string>>((attrs, attribute) => {
        attrs[attribute.name] = attribute.value
        return attrs
    }, {})
}


export function inlineSvg(id: string) {
    const $img = $.id(id)
    $.get($img.attr('src'), (xml: any) => {
        const $svg = $(xml).find('svg')

        const imgAttrs = getAttributes($img[0])
        delete imgAttrs.src

        $svg.attr(imgAttrs)

        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', `0 0 ${$svg.attr('height')} ${$svg.attr('width')}`)
        }
        $img.replaceWith($svg)
    }, 'xml')

    if(document.currentScript) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        document.currentScript.parentNode.removeChild(document.currentScript)
    }
}

const driverStatusMap = {
    ASSIGNED: 'driver-assigned',
    ACCEPTED: 'driver-accepted',
    DISPATCHED: 'driver-dispatched',
}

export type DriverStatus = keyof typeof driverStatusMap

export function driverStatusToClass(status: DriverStatus) {
    return driverStatusMap[status] || 'driver-tbd'
}

export function collapseWhitespace(str: string|nil): string {
    if(!str) return ''
    return str.replace(/[ \t\n\r\x00\x0B\x0C\xA0]+/g, ' ').trim()
}

/**
 * @deprecated
 */
export type MutablePromise<TValue, TError extends Error = Error> = Promise<TValue> & {
    resolve(x?: TValue): void
    reject(x?: TError): void
}

/**
 * @deprecated Prefer {@linkcode services/webpack/assets/scripts/util/deferred.ts|Deferred}
 */
export function createMutablePromise<T = unknown, U extends Error = Error>() {
    let resolve, reject
    const promise = new Promise<T>((r, j) => {
        resolve = r
        reject = j
    })
    Object.assign(promise, {resolve, reject})
    return promise as MutablePromise<T, U>
}

export function setLocalStorage(key: string, value: any): boolean {
    try {
        window.localStorage.setItem(key, jsonStringify(value))
        return true
    } catch(err) {
        captureSentryError(`Failed to store value in localStorage`, err, {
            key,
            value
        }, 'fbd858d5-6e19-47f2-a70e-78a9021949f3')
        return false
    }
}

export function getLocalStorage<T = any>(key: string, defaultValue?: T) {
    let value
    try {
        value = window.localStorage.getItem(key)
    } catch(err) {
        captureSentryError(`localStorage.getItem failed`, err, {key}, 'd4e274fb-a0b9-4432-a57d-34326c3412bb')
        return defaultValue
    }
    if(value === null) return defaultValue
    try {
        return JSON.parse(value)
    } catch(err) {
        captureSentryError(`Failed to parse value from localStorage`, err, {
            key,
            value
        }, '3a301180-2704-4ea3-8c6b-30a6cd05d610')
        return defaultValue
    }
}

export function freeze<T extends object>(obj: T) {
    // https://github.com/keithamus/proposal-object-freeze-seal-syntax
    return Object.freeze(Object.assign(Object.create(null), obj)) as Readonly<T>
}

// function createPipeline<TArgs extends any[],TRet>(...funcs: Array<(...args:TArgs)=>TRet>) {
//     const filtered = funcs.filter(f => f !== undefined)
//     let i = 0;
//
//     return (...args:TArgs) => {
//         let lastResult = args.shift()
//         const next = (...explicitArgs) => {
//             ++i
//             if(i < filtered.length) {
//                 lastResult = explicitArgs.length === args.length ? filtered[i](...explicitArgs,lastResult,next) : filtered[i](...args,lastResult,next)
//                 console.log('lastResult',lastResult)
//             }
//             return lastResult
//         }
//         return filtered[0](...args,lastResult,next)
//     }
// }


// https://stackoverflow.com/questions/69058552/typescript-how-can-i-specify-k-is-exactly-keyof-t

export function makeFullName(firstName: string|nil, lastName:string|nil): string {
    const first = (firstName??'').trim()
    const last = (lastName??'').trim()
    let name = ''
    if(first.length) {
        name = first
        if(last.length) {
            name += ' ' + last
        }
    } else if(last.length) {
        name = last
    }
    return name
}

/**
 * Count # of items that meet some condition.
 */
export function countIf<T>(iter: Iterable<T>, predicate: (x:T) => boolean): number {
    if(!iter) return 0
    let count = 0
    for(const x of iter) {
        if(predicate(x)) {
            ++count
        }
    }
    return count
}

// export function isFunction(obj: any): obj is AnyFn {
//     return typeof obj === 'function';
// }


// export function tryGet<T>(getter: () => T, fallback: T): T {
//     try {
//         return getter() ?? fallback
//     } catch(_) {
//         return fallback
//     }
// }


export function splitCodeWords(str: string): string[] {
    if(!str) return []
    return str
        .replace(/([a-z])['’](s)(?=\s|$)/uig, (_,a,b) => `${a}${b}`)
        .replace(/([a-z])([A-Z])/g, (_,a,b) => `${a} ${b}`)  // split CamelCase words
        .replace(/^[^a-z0-9]+|[^a-z0-9]+$/uig, '') // trim punctuation off ends
        .split(/[^a-z0-9'’]+/uig);
}

export function kebabToTitleCase(str: string): string {
    if(!str) return ''
    const TITLE_LOWER = new Set(['and','of','or','a','but','on','in','with'])
    return str.split(/-/g).map(w => {
        if(TITLE_LOWER.has(w)) return w
        return w.slice(0,1).toUpperCase()+w.slice(1)
    }).join(' ')
}
