import {OptionTuple} from '../helpers/react-helpers'
import {isEmptyInput} from './input'
import {EMPTY_OBJECT, NOOP} from '../helpers/constants'
import _ from 'lodash'
import _set from 'lodash/fp/set'
import {Resolvable, resolveValue} from './resolvable'

import {parseFloat} from './math'

export function mapMap<K, V, R>(map: Map<K, V> | nil, callback: (v: V, k: K, i: number) => R) {
    return !map ? [] : Array.from(map, ([k, v], i) => callback(v, k, i))
}

export function arrayMap<V, R>(arr: V[] | nil, callback: (v: V, i: number) => R) {
    return !arr ? [] : arr.map(callback)
}

export function iterMap<T,R>(iter: Iterable<T>, callback: (v: T, i:number)=>R) {
    const ret: R[] = []
    let i=0;
    for(const x of iter) {
        ret.push(callback(x,i++))
    }
    return ret
}

export function idMap(options: Iterable<OptionTuple>) {
    const map = new Map<number, string>()
    for(const [id, text] of options) {
        if(!isEmptyInput(id)) {
            map.set(Number(id), String(text))
        }
    }
    return map
}

export function groupBy<T, K>(arr: T[], fn: (x: T) => K): Map<K, T[]> {
    const out = new Map<K, T[]>()
    for(const x of arr) {
        const k = fn(x)
        const a = out.get(k)
        if(a != null) {
            a.push(x)
        } else {
            out.set(k, [x])
        }
    }
    return out
}

/**
 * Returns a function that takes in the current data and merges in new data.
 * Useful for passing into functions like React's setState which offer a callback variant.
 *
 * @param newObj Data to merge into existing object.
 */
export function mergeIn<T extends ExtendsObject>(newObj: Partial<T>) {
    return (oldObj: T) => mergeExisting(oldObj, newObj)
}

// https://stackoverflow.com/a/67452316/65387
/**
 * Returns the objects own, enumerable keys. Does not include symbols.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
 * @seealso https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Reflect/ownKeys
 */
export const objectKeys = <T extends ExtendsObject>(obj: T) => Object.keys(obj) as Array<keyof T>

// TODO: how is this different than mergeDefaults?
export function mergeExisting<T extends ExtendsObject>(oldObj: T, newObj: Partial<T>): T {
    if(oldObj == null) {
        throw new Error("Previous value is undefined, cannot pick keys to perform merge-update")
    }
    return {...oldObj, ...pickDefined(newObj, objectKeys(oldObj))}
}

export const __skip__ = Symbol('skip')

/**
 * Filter-map. Like map, but you may omit entries by returning `__skip__`.
 * @deprecated Use {@link mapDefined}
 */
export function legacyFilterMap<TItem, TReturn>(this: TItem[], callback: (this: TItem[], ...args: [currentValue: TItem, index: number, array: TItem[]]) => TReturn | typeof __skip__): TReturn[] {
    return Array.prototype.reduce.call<TItem[], [callbackfn: (previousValue: TReturn[], currentValue: TItem, currentIndex: number, array: TItem[]) => TReturn[], initialValue: TReturn[]], TReturn[]>(this, (accum: TReturn[], ...args) => {
        const x = callback.call(this, ...args)
        if(x !== __skip__) {
            accum.push(x)
        }
        return accum
    }, [])
}

/**
 * Like `Object.assign` but skips merging in values that are undefined.
 */
export function assignDefined<T extends ExtendsObject, U extends ExtendsObject>(target: T, sources: U): T & U;
export function assignDefined(target: UnknownObject, ...sources: UnknownObject[]): UnknownObject {
    for(const source of sources) {
        for(const [key, val] of Object.entries(source)) {
            if(val !== undefined) {
                target[key] = val
            }
        }
    }
    return target
}

/**
 * Like Array.prototype.map, but filters out undefined|null values.
 */
export function mapDefined<TItem, TReturn>(array: TItem[] | nil, callback: (value: TItem, index: number) => TReturn | null | undefined): TReturn[] {
    if(!array?.length) return []
    const accum: TReturn[] = []
    if(array?.length) {
        for(let i = 0; i < array.length; ++i) {
            const x = callback(array[i], i)
            if(x != null) {
                accum.push(x)
            }
        }
    }
    return accum
}

/**
 * Removes one instance of `value` from `array`, without mutating the original array. Uses loose comparison.
 */
export function arrayRemove<T>(array: Array<T>, value: T | Predicate<T>) {
    // let func: any = value
    // if(!_.isFunction(value)) {
    //     func = (v: T, i: number) => v == value
    // }

    const func = _.isFunction(value) ? value : (v: T) => v == value

    for(let i = 0; i < array.length; ++i) {
        if(func(array[i], i)) {
            return arraySplice(array, i)
            // const copy = [...array]
            // copy.splice(i, 1)
            // return copy
        }
    }
    return array
}

export function applyArrayRemove<T>(value: T | Predicate<T>) {
    return (array: T[]) => arrayRemove(array, value)
}

export function setRemove<T>(set: Set<T>, value: T): Set<T> {
    if(set.has(value)) {
        const copy = new Set(set)
        copy.delete(value)
        return copy
    }
    return set
}

export function arraySetAppend<T,S extends T[]|Set<T>>(array: S, value: T): S {
    if(array instanceof Set) {
        return new Set([...array, value]) as S
    }
    return [...array, value] as S
}

/**
 * Removes an index from an array without mutating the original array.
 *
 * @param array Array to remove value from
 * @param index Index to remove
 * @param count
 * @param replaceWith
 * @returns Array with `value` removed
 */
export function arraySplice<T>(array: T[], index: number, count = 1, replaceWith: T[] = []): T[] {
    return [
        ...array.slice(0, index),
        ...replaceWith,
        ...array.slice(index + count),
    ]
    // if(index < array.length) {
    //     const copy = [...array]
    //     copy.splice(index, count, ...replaceWith)
    //     return copy
    // }
    // return array
}

// Move an item from one index to another.
export function arrayMove<T>(array: T[], from: number, to: number, quantity: number=1): T[] {
    const next = array.toSpliced(from, quantity)
    next.splice(to, 0, ...array.slice(from, from + quantity))
    return next
}

export function arrayReplace<T>(array: T[], index: number, replaceWith: T): T[] {
    return arraySplice(array, index, 1, [replaceWith])
    // const copy = [...array]
    // copy.splice(index, 1, replaceWith)
    // return copy
}

export function arraySwap<T>(array: T[], indexA: number, indexB: number): T[] {
    if (indexA === indexB) return array; // No swap needed
    const copy = [...array]; // Create a shallow copy
    [copy[indexA], copy[indexB]] = [copy[indexB], copy[indexA]]; // Swap elements
    return copy;
}


type Predicate<T> = (value: T, index: number) => boolean

export function findAndReplace<T>(array: T[], predicate: Predicate<T>, replaceWith: Resolvable<T, [T]>): T[] {
    const idx = array.findIndex(predicate)
    if(idx >= 0) {
        return arrayReplace(array, idx, resolveValue(replaceWith, array[idx]!))
    }
    return array
}

export function applyFindAndReplace<T>(predicate: Predicate<T>, replaceWith: Resolvable<T, [T]>) {
    return (array: T[]) => findAndReplace(array, predicate, replaceWith)
}

export function arrayDeleteIndex<T>(array: T[], index: number): T[] {
    return arraySplice(array, index)
    // const copy = [...array]
    // copy.splice(index, 1)
    // return copy
}

/**
 * Checks if `value` is in collection. Uses loose comparison.
 */
export function iterableIncludes<T = any>(array: Iterable<T>, value: T) {
    if(array == null) return false
    for(const val of array) {
        if(val == value) {
            return true
        }
    }
    return false
}

export function arraySetRemove<T,S extends T[]|Set<T>>(set: S, value: T): S {
    if(set instanceof Set) {
        return setRemove(set, value) as S
    }
    return arrayRemove(set, value) as S
}

export function arraySetChange<T,S extends T[]|Set<T>>(checked: boolean, set: S, value: T):S {
    if(checked) {
        return arraySetAppend(set, value)
    }
    return arraySetRemove(set, value)
}

export function arraySetContains<T>(set: T[]|Set<T>, value: T): boolean {
    if(set instanceof Set) {
        return set.has(value)
    }
    return set.includes(value)
}

/**
 * Computes the sum of the array elements.
 */
export function arraySum(array: Array<string | number>): number {
    return array.reduce((accum: number, val: string | number) => accum + parseFloat(val, 0), 0)
}

export function mergeCallbacks<TArgs extends any[]>(...funcs: Array<((...args: TArgs) => void) | undefined>) {
    const filtered = funcs.filter(Boolean) as Array<(...args: TArgs) => void>
    if(filtered.length === 0) return NOOP
    if(filtered.length === 1) return filtered[0]
    return (...args: TArgs) => {
        for(const fn of filtered) {
            fn(...args)
        }
    }
}

/**
 * Merges options objects that contain callbacks.
 * Callbacks will be replaced with a new void function that executes the callback in every option object.
 */
export function mergeOptions<T extends ExtendsObject>(...objects: Array<T | undefined>): T {
    const filtered = objects.filter(f => f !== undefined) as T[]
    if(filtered.length === 0) return <T>EMPTY_OBJECT
    if(filtered.length === 1) return filtered[0]
    const funcs: Record<keyof T, AnyFn[]> = Object.create(null)
    const out: T = Object.create(null)
    for(const obj of filtered) {
        for(const key of objectKeys(obj)) {
            const fn = obj[key]
            if(_.isFunction(fn)) {
                if(funcs[key]) {
                    funcs[key].push(fn)
                } else {
                    funcs[key] = [fn]
                }
            } else if(fn !== undefined) {
                out[key] = fn
            }
        }
    }
    for(const key of objectKeys(funcs)) {
        (out[key] as AnyFn) = mergeCallbacks(...funcs[key])
    }
    return out
}

/**
 * Count the number of elements matching a condition.
 */
export function arrayCount<T>(arr: T[], predicate: (elem: T, idx: number) => boolean) {
    return arr.reduce((prev, curr, idx) => prev + (predicate(curr, idx) ? 1 : 0), 0)
}

/**
 * Pick a subset of keys from an object.
 * If the object doesn't contain a given key, the property will be set to undefined.
 */
export function pick<T, K extends keyof T>(data: T, keys: K[]): keyof T extends K ? T : Partial<T> {
    const ret = Object.create(null)
    for(const k of keys) {
        ret[k] = data[k]
    }
    return ret
}

/**
 * Functional-programming version of Pick.
 * Specify the keys to pick now, returns a function that takes the object to pick from.
 * Useful when combined with `formState.useState`
 */
export function fpPick<T, K extends keyof T>(keys: K[]) {
    return (data: T) => pick(data, keys)
}

/**
 * Pick a subset of keys from an object.
 * Returned object will the intersection of keys from `data` and `keys`; i.e. missing keys will not be set to undefined.
 */
export function pickStrict<T, K extends keyof T>(data: T, keys: K[]): Pick<T, K> {
    const ret = Object.create(null)
    for(const k of keys) {
        if(Object.hasOwnProperty.call(data, k)) {
            ret[k] = data[k]
        } else {
            throw new Error(`Picked key "${String(k)}" not found in object`)
        }
    }
    return ret
}

export function pickDefined<T, K extends keyof T>(data: T, keys: K[]): Pick<T, K> {
    const ret = Object.create(null)
    for(const k of keys) {
        const val = data[k]
        if(val !== undefined) {
            ret[k] = val
        }
    }
    return ret
}

/**
 * Pick a subset of keys from an object whose keys are in `defaults`.
 * If a key is undefined, use the value from `defaults` instead.
 * @see assignDefined
 */
export function mergeDefaults<T>(data: Partial<T>, defaults: Full<T>): Full<T> {
    const ret = Object.create(null)
    for(const k of objectKeys(defaults)) {
        const val = data[k]
        ret[k] = val === undefined ? defaults[k] : val
    }
    return ret
}

export function setKey<TObj extends object, TKey extends _.PropertyPath>(obj: TObj, name: TKey, value: TKey extends keyof TObj ? TObj[TKey] : any): TObj {
    return _set(name, value, obj)
}

export function rangeInclusive(min: number, max: number) {
    return Array.from({length: max - min + 1}, (_, i) => i + min)
}
