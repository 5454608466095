// https://github.com/microsoft/TypeScript/issues/1897#issuecomment-648484759
// https://stackoverflow.com/a/64408600/65387
type JsonPrimitive = string | number | boolean | null;
export type JsonRequestObject = {
    [key: string]: JsonRequest | undefined  // allow undefined here because these keys will be stripped when stringified
}
type JsonMap = Map<string, JsonRequest>  // will be converted into an object
type JsonArray = Array<JsonRequest>;
type JsonSet = Set<JsonPrimitive>  // will be converted into an array

/**
 * Represents an object that can be serialized with jsonStringify() (not the native JSON.stringify)
 */
export type JsonRequest = JsonPrimitive | JsonRequestObject | JsonArray | JsonSet | JsonMap

// export type JsonRecord = Record<string, JsonResponse>

/**
 * Represents a possible return value from JSON.parse
 */
export type JsonResponse = JsonPrimitive | JsonResponse[] | JsonResponseObject;
export type JsonResponseObject = { [key: string]: JsonResponse };

type DefinitelyNotJsonable = ((...args: any[]) => any)


// https://github.com/microsoft/TypeScript/issues/1897#issuecomment-710744173
export type Jsonable<T> =
    [Extract<T, DefinitelyNotJsonable>] extends [never]
        ? T extends JsonPrimitive
            ? T
            : T extends (infer U)[]
                ? Jsonable<U>[]
                : T extends object
                    ? {
                        [P in keyof T]:
                        Jsonable<T[P]>
                    }
                    : never
            : never

function jsonReplacer(this: any, key: string, value: any): any {
    if(value instanceof Set) {
        return Array.from(value)
    }
    if(value instanceof Map) {
        return Object.fromEntries(value.entries())
    }
    return value
}

export function jsonStringify(obj: JsonRequest, space?: string | number): string {
    return JSON.stringify(obj, jsonReplacer, space)
}
