import React, {forwardRef} from 'react'
import {ComponentPropsWithClass, withClass} from '../helpers/react-helpers'
import cn from 'classnames'
import {useTestId} from '../contexts/testid'

interface ButtonProps extends ComponentPropsWithClass<'button'> {
    width?: string | number
    testId?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({width, style, className, testId, ...props}, ref) => (
        <button
            ref={ref}
            className={cn('btn', className)}
            data-testid={useTestId(testId)}
            style={{width, minWidth: width, ...style}}
            {...props}
        />
    )
)
if(__DEV__) {
    Button.displayName = 'Button'
}

export default Button
